:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1250px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --map-marker-color: 000;
    /*Colors*/
    --text-color: #303030;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #39959a;
    --effect-color: #93cfd2;
    --header-text-color: var(--main-color);
    --header-bg-color: #a15;
    --footer-bg-color: #45062E;
    --footer-text-color: #fff;
    --menu-text-color: #fff;
    --menu-text-hover-color: #fff;  
    /*Fonts*/
    --base-font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    --alternative-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@import "/fonts/Open Sans/all.css";
@import "/fonts/Roboto/all.css";

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
/*@import "/css/pikaday.css";*/
/*@import "/css/animate.css";*/

a.startarrow {
    margin: 20px 0px 0px -45px;
    text-align: center;
    z-index: 10;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 90px;
}
.startarrow .fa-icon {
    fill: #fff;
    height: 90px;
    width: auto;
}
.scrolled .startarrow .fa-icon {
    opacity: 0;
}


.mat {
    background: #fdebe0;
}
.inner {
    padding: 10px;
}
.block {
    background: #fff;
}

.homepage .block {
    background: #E8D6CB;
}

/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
main {
    border-top: 74px solid transparent;
}

body {
    font-family: var(--base-font-family);
    line-height: 1.5;
    font-weight: 300;
    color: var(--text-color);
    background: var(--body-bg);

}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    display: block;
}

.imagetop {
    min-height: 50vh;
    background: no-repeat center center;
    background-size: cover;
    position: relative;
}

.imagetop:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    background: #E8D6CB;
}

.kontakt .imagetop {
    display: none;
}

.kontakt .wrapper {
    min-height: 0vh;
}
figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

/* ------------------------------- FONTS + HEADINGS + BUTTON ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    margin: 0.5em 0 0 0; 
    font-weight: 600;
    color: #45062e;
}
.slick-title {
    font-size: 2.6rem;
    font-family: var(--alternative-font-family);
    font-weight: 600;
    text-align: center;
}
.button {
    margin: 1rem 0px;
    background: #e0e0e0;
    border: none;
    transition: background 1s;
}

.button:hover {
    background: #E3E3E3;
}

/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: 50vh;
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */
/*Slick slider*/
.slick-wrapper {min-height:93vh; position:relative; flex-wrap:wrap; }
.slick-slider, .slick-substitute > div, .slick-slide {min-height:93vh; margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { width:100%; height:100%; top:0; left:0; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center center; display:flex !important;}   
.slick {visibility: hidden;} .slick-initialized {visibility: visible;} .slick-list, .slick-track {height:100%;}
.slick-slider, .slick-substitute > div {position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; }

/*Starttext*/ 
.starttext {padding: 20px; width:100%; text-align:left; background: #fff; max-width: 650px; margin: 100px; z-index: 1; border-radius: 8px;}
.starttext h1 {margin-top: 0px; }
.starttext strong {color: #a15;}

/*Under 800px*/
@media (max-width: var(--breakpoint-s))
{
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide {min-height:55vw;}
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */


/* ------------------STARTBOXARNA----------- */
.boxwrapper {
    min-height: 32vh;
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption  {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.boxwrapper[style]:before  {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .3s ease-in 0s;
}

.boxwrapper figure img {

 transition: transform .3s ease-in 0s;
}

.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .3s ease-in 0s;
    z-index: 2;
}


.boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .8s ease-in 0s;

}

.boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .8s ease-in 0s;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    padding: 0.8rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    font-size: 2.2rem;
    z-index: 3;
}
/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}
.newsitem hr {
    color: #efefef;
}

/*------------------------------------------------------------------------------------------------ 
General content--------------------------------------------------------------------------------*/

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}


/* -------------- logo gallery ------------ */
.logotype-gallery {
    padding: 2em 0;
}
.logotype-image {
    min-height: 70px;
} 
.logotype-image div, .logotype-image a {
    text-align: center; 
    width: 100%; 
    flex:0 0 auto; 
    display: block;
} 
.logotype-image img {
    height: auto;
} 
.standing  {
    width:40%; 
} 
.squarish  {
    width:40%; 
} 
.landscape  {
    width:55%; 
}
.longlandscape {
    width:70%; 
}
.extralonglandscape {
    width:100%; 
}

/* -------------- mfp-ajax ------------ */

.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}


/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */


#menu-icon {
    color: #fff;
}
.logo a {
    font-size: 2em;
    font-weight: 400;
}
header {
    background: var(--header-bg-color);
    z-index: 10;
    width: 100%;
    position: fixed; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
}
.logo {
    padding: 10px;
}
.logo img {
    max-height: 50px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}
header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    color: var(--menu-text-color);
    font-size: 1.1em;
    letter-spacing: 1px;
    font-weight: 400;
    white-space: nowrap;
        border-bottom: 1px solid transparent;

}
.menu.menu li a:hover, .menu.menu li.active a {
    color: var(--menu-text-hover-color);
    border-bottom: 1px solid #fff;
}


nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; transform: rotate(-90deg); }

header nav ul.menu .dropdown ul,  ul.sub-menu-items {
    background: #960c49;
}
.menu .dropdown ul {
    border-bottom: 0px;
}
.menu .dropdown ul li a {
    margin: 0px;
}
/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }
    header nav ul.menu li a {
        color: #45062e;
    }
    .menu.menu li a:hover, .menu.menu li.active a {
        color: #a15;
    }
    .menu .dropdown ul {
        border-left: 3px solid #45062e;
    }
    .menu.show {
        max-height: calc(100vh - 79px);
    }
}



/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: var(--footer-bg-color);
    padding: 30px 0;
}
footer, footer a, footer a:hover { 
    color: var(--footer-text-color); 
}
footer svg {
    margin-right: 10px;
    fill: var(--footer-text-color);
}
footer h4 {
    margin-top:0;
    color: #fff;
}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: var(--footer-bg-color);
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #751e55;
    line-height:1.1;
}
p.intenditfooter {
    margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */
.sociala svg {
    fill: #fff;
     height: 40px;
    width: 40px;
    padding: 10px;

}
.sociala a {
    background: #610c42;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: block;
    transition: background 0.5s ease;

}
.sociala a:hover {
    background: #7b1355;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: block;

}






.boltform form { 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
}

.boltforms-row {
    flex-basis: calc(100% - 20px);
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: content-box;
}

.boltforms-six-row {
    flex-basis: calc(50% - 20px);
}

.boltforms-four-row {
    flex-basis: calc((100% / 3) - 20px);
}

.boltforms-three-row {
    flex-basis: calc(25% - 20px);
}

.boltform textarea, .boltform input {
    width: 100%;
    color: #fff;
}
.boltform textarea {
    height: 100px;
    
}


@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }

    .boltform form {
        flex-direction: column;
    }
}

.formwrapper {
    background-color: #AA1155; 
    padding: 20px 0px;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #960c49;
    border: none;
    width: 100%;
    transition: background 0.5s ease;
}

.boltforms-row button:hover {
    background: #84073e;
    color: #fff;

}

.boltforms-row label {
   display: none !important;
}


.boltform textarea, .boltform input {
    width: 100%;
    background: rgba(255, 255, 255, 0.11);
    border: 0px;

}


.contactform h2 {
    padding-left: 10px;
    margin-bottom: 0.5em;
    color: #fff;
}


::-webkit-input-placeholder { color:#fff; }
::-moz-placeholder { color:#fff; } /* firefox 19+ */
:-ms-input-placeholder { color:#fff; } /* ie */
input:-moz-placeholder { color:#fff; }
/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 44vh;
    filter: grayscale(100%);
}

.map-canvas img {
    max-width: none;
}
.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 15px;
    background-position: center;
    font-size: 0;
}

.markerLabels {
    overflow: visible !important;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under mobilmeny----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{

}

/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 

    .formwrapper {
        padding-top: 1vh;
    }
    header nav ul.menu li a {
        font-size: 1em;
    }
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
     header nav ul.menu li a {
        font-size: 0.9em;
    }
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

    .container {
        width: 96%; 
    } 
    .starttext {
        margin: 50px;
        background: rgba(255, 255, 255, 0.8);
    }



}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .container.sml {
        width: 96%; 
    } 

    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
      .starttext {
        margin: 50px; margin-top: 200px;
    }


}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logo img {
       max-height: 34px;
    }
      .starttext {
        margin: 20px;
    }

}
