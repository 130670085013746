/*!
 *! Flex Grid
 */

*{ min-width: 0px; }

.hide { display: none !important }
.block,
.show { display: block !important }
.inline { display: inline !important }
.inline-block { display: inline-block !important }


.align,
.flex { display: flex }

.flex>* {
    min-width: 0px;
    max-width:100%;
    box-sizing: content-box;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    margin: 0;
}

.flex {
    flex-wrap: wrap;
    flex-direction: row;
}

.padding>* { padding: var(--vertical-padding) var(--padding) }
.margin>* { margin: var(--vertical-margin) var(--margin) }

.col.padding>* { padding: var(--padding) var(--vertical-padding) }
.col.margin>* { margin: var(--margin) var(--vertical-margin) }

.no-grow { flex-grow: 0 }
.no-shrink { flex-shrink: 0 }
.col { flex-direction: column }

.center {
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bottom,
.top,
.left,
.right,
.space-around,
.space-between { flex-direction: row }
.right { justify-content: flex-end }
.left { justify-content: flex-start }
.top { align-items: flex-start }
.bottom { align-items: flex-end }
.space-around { justify-content: space-around }
.space-between { justify-content: space-between }

.one { flex-basis: 8.333333333% }
.two { flex-basis: 16.666666667% }
.fifth { flex-basis: 20% }
.three { flex-basis: 25% }
.four { flex-basis: 33.333333333% }
.five { flex-basis: 41.666666667% }
.six { flex-basis: 50% }
.seven { flex-basis: 58.333333333% }
.eight { flex-basis: 66.666666667% }
.nine { flex-basis: 75% }
.ten { flex-basis: 83.333333333% }
.eleven { flex-basis: 91.666666667% }
.twelve { flex-basis: 100% }

.margin>.one { flex-basis: calc(8.333333333% - var(--margin) * 2) }
.margin>.two { flex-basis: calc(16.666666667% - var(--margin) * 2) }
.margin>.fifth { flex-basis: calc(20% - var(--margin) * 2) }
.margin>.three { flex-basis: calc(25% - var(--margin) * 2) }
.margin>.four { flex-basis: calc(33.333333333% - var(--margin) * 2) }
.margin>.five { flex-basis: calc(41.666666667% - var(--margin) * 2) }
.margin>.six { flex-basis: calc(50% - var(--margin) * 2) }
.margin>.seven { flex-basis: calc(58.333333333% - var(--margin) * 2) }
.margin>.eight { flex-basis: calc(66.666666667% - var(--margin) * 2) }
.margin>.nine { flex-basis: calc(75% - var(--margin) * 2) }
.margin>.ten { flex-basis: calc(83.333333333% - var(--margin) * 2) }
.margin>.eleven { flex-basis: calc(91.666666667% - var(--margin) * 2) }
.margin>.twelve { flex-basis: calc(100% - var(--margin) * 2) }

.padding>.one { flex-basis: calc(8.333333333% - var(--padding) * 2) }
.padding>.two { flex-basis: calc(16.666666667% - var(--padding) * 2) }
.padding>.fifth { flex-basis: calc(20% - var(--margin) * 2) }
.padding>.three { flex-basis: calc(25% - var(--padding) * 2) }
.padding>.four { flex-basis: calc(33.333333333% - var(--padding) * 2) }
.padding>.five { flex-basis: calc(41.666666667% - var(--padding) * 2) }
.padding>.six { flex-basis: calc(50% - var(--padding) * 2) }
.padding>.seven { flex-basis: calc(58.333333333% - var(--padding) * 2) }
.padding>.eight { flex-basis: calc(66.666666667% - var(--padding) * 2) }
.padding>.nine { flex-basis: calc(75% - var(--padding) * 2) }
.padding>.ten { flex-basis: calc(83.333333333% - var(--padding) * 2) }
.padding>.eleven { flex-basis: calc(91.666666667% - var(--padding) * 2) }
.padding>.twelve { flex-basis: calc(100% - var(--padding) * 2) }

.margin.padding>.one { flex-basis: calc(8.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.two { flex-basis: calc(16.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.fifth { flex-basis: calc(20% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.three { flex-basis: calc(25% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.four { flex-basis: calc(33.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.five { flex-basis: calc(41.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.six { flex-basis: calc(50% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.seven { flex-basis: calc(58.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.eight { flex-basis: calc(66.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.nine { flex-basis: calc(75% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.ten { flex-basis: calc(83.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.eleven { flex-basis: calc(91.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
.margin.padding>.twelve { flex-basis: calc(100% - (var(--padding) * 2 + var(--margin) * 2)) }

@media(max-width: var(--breakpoint-m)) {
    .m-hide { display: none !important }
    .m-block,
    .m-show { display: block !important }
    .m-inline { display: inline !important }
    .m-inline-block { display: inline-block !important }

    .m-align,
    .m-flex { display: flex }

    .m-flex>* {
        max-width:100%;
        box-sizing: content-box;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        margin: 0;
    }

    .m-flex {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .m-no-grow { flex-grow: 0 }
    .m-no-shrink { flex-shrink: 0 }
    .m-col { flex-direction: column }

    .m-center {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .m-bottom,
    .m-top,
    .m-left,
    .m-right,
    .m-space-around,
    .m-space-between { flex-direction: row }
    .m-right { justify-content: flex-end }
    .m-left { justify-content: flex-start }
    .m-top { align-items: flex-start }
    .m-bottom { align-items: flex-end }
    .m-space-around { justify-content: space-around }
    .m-space-between { justify-content: space-between }
    
    .m-one { flex-basis: 8.333333333% }
    .m-two { flex-basis: 16.666666667% }
    .m-fifth { flex-basis: 20% }
    .m-three { flex-basis: 25% }
    .m-four { flex-basis: 33.333333333% }
    .m-five { flex-basis: 41.666666667% }
    .m-six { flex-basis: 50% }
    .m-seven { flex-basis: 58.333333333% }
    .m-eight { flex-basis: 66.666666667% }
    .m-nine { flex-basis: 75% }
    .m-ten { flex-basis: 83.333333333% }
    .m-eleven { flex-basis: 91.666666667% }
    .m-twelve { flex-basis: 100% }
    .margin>.m-one { flex-basis: calc(8.333333333% - var(--margin) * 2) }
    .margin>.m-two { flex-basis: calc(16.666666667% - var(--margin) * 2) }
    .margin>.m-fifth { flex-basis: calc(20% - var(--margin) * 2) }
    .margin>.m-three { flex-basis: calc(25% - var(--margin) * 2) }
    .margin>.m-four { flex-basis: calc(33.333333333% - var(--margin) * 2) }
    .margin>.m-five { flex-basis: calc(41.666666667% - var(--margin) * 2) }
    .margin>.m-six { flex-basis: calc(50% - var(--margin) * 2) }
    .margin>.m-seven { flex-basis: calc(58.333333333% - var(--margin) * 2) }
    .margin>.m-eight { flex-basis: calc(66.666666667% - var(--margin) * 2) }
    .margin>.m-nine { flex-basis: calc(75% - var(--margin) * 2) }
    .margin>.m-ten { flex-basis: calc(83.333333333% - var(--margin) * 2) }
    .margin>.m-eleven { flex-basis: calc(91.666666667% - var(--margin) * 2) }
    .margin>.m-twelve { flex-basis: calc(100% - var(--margin) * 2) }
    .padding>.m-one { flex-basis: calc(8.333333333% - var(--padding) * 2) }
    .padding>.m-two { flex-basis: calc(16.666666667% - var(--padding) * 2) }
    .padding>.m-fifth { flex-basis: calc(20% - var(--margin) * 2) }
    .padding>.m-three { flex-basis: calc(25% - var(--padding) * 2) }
    .padding>.m-four { flex-basis: calc(33.333333333% - var(--padding) * 2) }
    .padding>.m-five { flex-basis: calc(41.666666667% - var(--padding) * 2) }
    .padding>.m-six { flex-basis: calc(50% - var(--padding) * 2) }
    .padding>.m-seven { flex-basis: calc(58.333333333% - var(--padding) * 2) }
    .padding>.m-eight { flex-basis: calc(66.666666667% - var(--padding) * 2) }
    .padding>.m-nine { flex-basis: calc(75% - var(--padding) * 2) }
    .padding>.m-ten { flex-basis: calc(83.333333333% - var(--padding) * 2) }
    .padding>.m-eleven { flex-basis: calc(91.666666667% - var(--padding) * 2) }
    .padding>.m-twelve { flex-basis: calc(100% - var(--padding) * 2) }
    .margin.padding>.m-one { flex-basis: calc(8.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-two { flex-basis: calc(16.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-fifth { flex-basis: calc(20% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-three { flex-basis: calc(25% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-four { flex-basis: calc(33.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-five { flex-basis: calc(41.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-six { flex-basis: calc(50% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-seven { flex-basis: calc(58.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-eight { flex-basis: calc(66.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-nine { flex-basis: calc(75% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-ten { flex-basis: calc(83.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-eleven { flex-basis: calc(91.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.m-twelve { flex-basis: calc(100% - (var(--padding) * 2 + var(--margin) * 2)) }
}

@media(max-width: var(--breakpoint-s)) {
    .s-hide { display: none !important }
    .s-block,
    .s-show { display: block !important }
    .s-inline { display: inline !important }
    .s-inline-block { display: inline-block !important }

    .s-align,
    .s-flex { display: flex }

    .s-flex>* {
        max-width:100%;
        box-sizing: content-box;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        margin: 0;
    }

    .s-flex {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .s-no-grow { flex-grow: 0 }
    .s-no-shrink { flex-shrink: 0 }
    .s-col { flex-direction: column }

    .s-center {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .s-bottom,
    .s-top,
    .s-left,
    .s-right,
    .s-space-around,
    .s-space-between { flex-direction: row }
    .s-right { justify-content: flex-end }
    .s-left { justify-content: flex-start }
    .s-top { align-items: flex-start }
    .s-bottom { align-items: flex-end }
    .s-space-around { justify-content: space-around }
    .s-space-between { justify-content: space-between }
    
    .s-one { flex-basis: 8.333333333% }
    .s-two { flex-basis: 16.666666667% }
    .s-fifth { flex-basis: 20% }
    .s-three { flex-basis: 25% }
    .s-four { flex-basis: 33.333333333% }
    .s-five { flex-basis: 41.666666667% }
    .s-six { flex-basis: 50% }
    .s-seven { flex-basis: 58.333333333% }
    .s-eight { flex-basis: 66.666666667% }
    .s-nine { flex-basis: 75% }
    .s-ten { flex-basis: 83.333333333% }
    .s-eleven { flex-basis: 91.666666667% }
    .s-twelve { flex-basis: 100% }
    
    .margin>.s-one { flex-basis: calc(8.333333333% - var(--margin) * 2) }
    .margin>.s-two { flex-basis: calc(16.666666667% - var(--margin) * 2) }
    .margin>.s-fifth { flex-basis: calc(20% - var(--margin) * 2) }
    .margin>.s-three { flex-basis: calc(25% - var(--margin) * 2) }
    .margin>.s-four { flex-basis: calc(33.333333333% - var(--margin) * 2) }
    .margin>.s-five { flex-basis: calc(41.666666667% - var(--margin) * 2) }
    .margin>.s-six { flex-basis: calc(50% - var(--margin) * 2) }
    .margin>.s-seven { flex-basis: calc(58.333333333% - var(--margin) * 2) }
    .margin>.s-eight { flex-basis: calc(66.666666667% - var(--margin) * 2) }
    .margin>.s-nine { flex-basis: calc(75% - var(--margin) * 2) }
    .margin>.s-ten { flex-basis: calc(83.333333333% - var(--margin) * 2) }
    .margin>.s-eleven { flex-basis: calc(91.666666667% - var(--margin) * 2) }
    .margin>.s-twelve { flex-basis: calc(100% - var(--margin) * 2) }
    
    .padding>.s-one { flex-basis: calc(8.333333333% - var(--padding) * 2) }
    .padding>.s-two { flex-basis: calc(16.666666667% - var(--padding) * 2) }
    .padding>.s-fifth { flex-basis: calc(20% - var(--margin) * 2) }
    .padding>.s-three { flex-basis: calc(25% - var(--padding) * 2) }
    .padding>.s-four { flex-basis: calc(33.333333333% - var(--padding) * 2) }
    .padding>.s-five { flex-basis: calc(41.666666667% - var(--padding) * 2) }
    .padding>.s-six { flex-basis: calc(50% - var(--padding) * 2) }
    .padding>.s-seven { flex-basis: calc(58.333333333% - var(--padding) * 2) }
    .padding>.s-eight { flex-basis: calc(66.666666667% - var(--padding) * 2) }
    .padding>.s-nine { flex-basis: calc(75% - var(--padding) * 2) }
    .padding>.s-ten { flex-basis: calc(83.333333333% - var(--padding) * 2) }
    .padding>.s-eleven { flex-basis: calc(91.666666667% - var(--padding) * 2) }
    .padding>.s-twelve { flex-basis: calc(100% - var(--padding) * 2) }
    
    .margin.padding>.s-one { flex-basis: calc(8.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-two { flex-basis: calc(16.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-fifth { flex-basis: calc(20% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-three { flex-basis: calc(25% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-four { flex-basis: calc(33.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-five { flex-basis: calc(41.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-six { flex-basis: calc(50% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-seven { flex-basis: calc(58.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-eight { flex-basis: calc(66.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-nine { flex-basis: calc(75% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-ten { flex-basis: calc(83.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-eleven { flex-basis: calc(91.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.s-twelve { flex-basis: calc(100% - (var(--padding) * 2 + var(--margin) * 2)) }
}

@media(max-width: var(--breakpoint-xs)) {
    .xs-hide { display: none !important }
    .xs-block,
    .xs-show { display: block !important }
    .xs-inline { display: inline !important }
    .xs-inline-block { display: inline-block !important }

    .xs-align,
    .xs-flex { display: flex }

    .xs-flex>* {
        max-width:100%;
        box-sizing: content-box;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        margin: 0;
    }

    .xs-flex {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .xs-no-grow { flex-grow: 0 }
    .xs-no-shrink { flex-shrink: 0 }
    .xs-col { flex-direction: column }

    .xs-center {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .xs-bottom,
    .xs-top,
    .xs-left,
    .xs-right,
    .xs-space-around,
    .xs-space-between { flex-direction: row }
    .xs-right { justify-content: flex-end }
    .xs-left { justify-content: flex-start }
    .xs-top { align-items: flex-start }
    .xs-bottom { align-items: flex-end }
    .xs-space-around { justify-content: space-around }
    .xs-space-between { justify-content: space-between }
    
    .xs-one { flex-basis: 8.333333333% }
    .xs-two { flex-basis: 16.666666667% }
    .xs-fifth { flex-basis: 20% }
    .xs-three { flex-basis: 25% }
    .xs-four { flex-basis: 33.333333333% }
    .xs-five { flex-basis: 41.666666667% }
    .xs-six { flex-basis: 50% }
    .xs-seven { flex-basis: 58.333333333% }
    .xs-eight { flex-basis: 66.666666667% }
    .xs-nine { flex-basis: 75% }
    .xs-ten { flex-basis: 83.333333333% }
    .xs-eleven { flex-basis: 91.666666667% }
    .xs-twelve { flex-basis: 100% }

    .margin>.xs-one { flex-basis: calc(8.333333333% - var(--margin) * 2) }
    .margin>.xs-two { flex-basis: calc(16.666666667% - var(--margin) * 2) }
    .margin>.xs-fifth { flex-basis: calc(20% - var(--margin) * 2) }
    .margin>.xs-three { flex-basis: calc(25% - var(--margin) * 2) }
    .margin>.xs-four { flex-basis: calc(33.333333333% - var(--margin) * 2) }
    .margin>.xs-five { flex-basis: calc(41.666666667% - var(--margin) * 2) }
    .margin>.xs-six { flex-basis: calc(50% - var(--margin) * 2) }
    .margin>.xs-seven { flex-basis: calc(58.333333333% - var(--margin) * 2) }
    .margin>.xs-eight { flex-basis: calc(66.666666667% - var(--margin) * 2) }
    .margin>.xs-nine { flex-basis: calc(75% - var(--margin) * 2) }
    .margin>.xs-ten { flex-basis: calc(83.333333333% - var(--margin) * 2) }
    .margin>.xs-eleven { flex-basis: calc(91.666666667% - var(--margin) * 2) }
    .margin>.xs-twelve { flex-basis: calc(100% - var(--margin) * 2) }

    .padding>.xs-one { flex-basis: calc(8.333333333% - var(--padding) * 2) }
    .padding>.xs-two { flex-basis: calc(16.666666667% - var(--padding) * 2) }
    .padding>.xs-fifth { flex-basis: calc(20% - var(--margin) * 2) }
    .padding>.xs-three { flex-basis: calc(25% - var(--padding) * 2) }
    .padding>.xs-four { flex-basis: calc(33.333333333% - var(--padding) * 2) }
    .padding>.xs-five { flex-basis: calc(41.666666667% - var(--padding) * 2) }
    .padding>.xs-six { flex-basis: calc(50% - var(--padding) * 2) }
    .padding>.xs-seven { flex-basis: calc(58.333333333% - var(--padding) * 2) }
    .padding>.xs-eight { flex-basis: calc(66.666666667% - var(--padding) * 2) }
    .padding>.xs-nine { flex-basis: calc(75% - var(--padding) * 2) }
    .padding>.xs-ten { flex-basis: calc(83.333333333% - var(--padding) * 2) }
    .padding>.xs-eleven { flex-basis: calc(91.666666667% - var(--padding) * 2) }
    .padding>.xs-twelve { flex-basis: calc(100% - var(--padding) * 2) }

    .margin.padding>.xs-one { flex-basis: calc(8.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-two { flex-basis: calc(16.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-fifth { flex-basis: calc(20% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-three { flex-basis: calc(25% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-four { flex-basis: calc(33.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-five { flex-basis: calc(41.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-six { flex-basis: calc(50% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-seven { flex-basis: calc(58.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-eight { flex-basis: calc(66.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-nine { flex-basis: calc(75% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-ten { flex-basis: calc(83.333333333% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-eleven { flex-basis: calc(91.666666667% - (var(--padding) * 2 + var(--margin) * 2)) }
    .margin.padding>.xs-twelve { flex-basis: calc(100% - (var(--padding) * 2 + var(--margin) * 2)) }
}
